<template>
  <section id="app">
    <header class="d-flex justify-content-between">
      <!-- redes -->
      <div class="p-2">
        <!-- Whatsapp -->
        <a
          aria-label="Icono de Whatsapp"
          href="https://api.whatsapp.com/message/DFXAEG33VKIZH1?autoload=1&app_absent=0"
          target="_blank"
          rel="noopener"
          class="btn btn-outline-light btn-floating border border-light"
          role="button"
          ><i class="fa-brands fa-whatsapp"></i
        ><p class="d-none d-inline comment">Whatsapp</p></a>

        <!-- Instagram -->
        <a
          aria-label="Icono de Instagram"
          href="https://www.instagram.com/orbita.com.co/"
          target="_blank"
          rel="noopener"
          class="btn btn-outline-light btn-floating border border-light mx-1"
          role="button"
          ><i class="fa-brands fa-instagram"></i
        ><p class="d-none d-inline comment">Instagram</p></a>

        <!-- Linkedin -->
        <a
          aria-label="Icono de Linkedin"
          href="https://www.linkedin.com/company/iorbita/"
          target="_blank"
          rel="noopener"
          class="btn btn-outline-light btn-floating border border-light mx-1"
          role="button"
          ><i class="fa-brands fa-linkedin"></i
        ><p class="d-none d-inline comment">Linkedin</p></a>

        <!-- youtube -->
        <a
          aria-label="Icono de youtube"
          href="https://youtube.com/@DesarrollandoElFuturo?si=0gOr3DwEAEQqwqqy"
          target="_blank"
          rel="noopener"
          class="btn btn-outline-light btn-floating border border-light mx-1"
          role="button"
        >
          <i class="fa-brands fa-youtube"></i
        ><p class="d-none d-inline comment">youtube</p></a>
      </div>

      <!-- logo -->
      <div class="">
        <!-- escritorio -->
        <img
          v-if="activeComponent.name != 'OrbitaInicio'"
          class="logo-orbita d-none d-sm-none d-md-block"
          src="https://static.orbita.com.co/public/general/images/Logo_Amarillo_Orbita.png"
          alt="logo orbita amarillo"
        />
        <!-- Movil -->
        <img
          v-if="activeComponent.name != 'OrbitaInicio'"
          class="logo-orbita d-block d-sm-block d-md-none"
          src="https://static.orbita.com.co/public/general/images/Icono_Amarillo_Orbita.png"
          alt="logo orbita amarillo"
        />
      </div>
    </header>

    <main>
      <!-- contenido -->
      <div class="card-general">
        <Transition name="fade" mode="out-in">
          <component :is="activeComponent"></component>
        </Transition>
      </div>

      <!-- Navegacion escritorio-->
      <div class="d-flex justify-content-center pt-lg-2">
        <div class="row justify-content-center gap-3 pt-lg-2">
          <button
            id="btnInicio"
            aria-label="Inicio"
            class="btn button rounded-pill col-6 col-sm-3 col-lg-2"
            :class="{ active: activeComponent === OrbitaInicio }"
            @click="activeComponent = OrbitaInicio"
          >
            <span class="d-none d-md-inline">Inicio</span>
            <i class="d-md-none bi bi-house"></i>
            
          </button>

          <button
            taria-label="¿Quienes Somos?"
            id="btnQuieness"
            class="btn button rounded-pill col-6 col-sm-3 col-md-2 col-lg-2 texto"
            :class="{ active: activeComponent === OrbitaQuieness }"
            @click="activeComponent = OrbitaQuieness"
          >
            <span class="d-none d-md-inline texto">¿Quienes Somos?</span>
            <i class="d-md-none bi bi-file-earmark-person"></i>            
          </button>

          <button
          aria-label="Servicios"
            id="btnServicios"
            class="btn button rounded-pill col-6 col-sm-3 col-md-2 col-lg-2 texto"
            :class="{ active: activeComponent === OrbitaServicios }"
            @click="activeComponent = OrbitaServicios"
          >
            <span class="d-none d-md-inline">Servicios</span>
            <i class="d-md-none bi bi-card-checklist"></i>
            
          </button>

          <button
          aria-label="Planes"
            id="btnPlanes"
            class="btn button rounded-pill col-6 col-sm-3 col-md-2 col-lg-4 texto"
            :class="{ active: activeComponent === OrbitaPlanes }"
            @click="activeComponent = OrbitaPlanes"
          >
            <span class="d-none d-md-inline">Planes</span>
            <i class="d-md-none bi bi-cart"></i>
            
          </button>

          <button
          aria-label="Equipo"
            id="btnEquipo"
            class="btn button rounded-pill col-6 col-sm-3 col-md-2 col-lg-4 texto"
            :class="{ active: activeComponent === OrbitaEquipo }"
            @click="activeComponent = OrbitaEquipo"
          >
            <span class="d-none d-md-inline">Equipo</span>
            <i class="d-md-none bi bi-person-heart"></i>
            
          </button>

          <button
            aria-label="Contactanos"
            id="btnContactanos"
            class="btn button rounded-pill  m-0 p-0 col-6 col-sm-3 col-md-2 col-lg-2 texto"
            :class="{ active: activeComponent === OrbitaContactanos }"
            @click="activeComponent = OrbitaContactanos"
          >
            <span class="d-none d-md-inline m-0 p-0">Contactanos</span>
            <i class="d-md-none bi bi-phone"></i>
          </button>
        </div>
      </div>
    </main>

    <footer>
      <div class="d-flex justify-content-center align-content-center">
        <p class="d-flex justify-content-center pt-2">
          ©2020 al 2024 Copyright <a href="#" class="pt-0 mt-0 p-1"> www.orbita.com.co</a>
        </p>
      </div>
    </footer>
  </section>
</template>

<script setup>
import { shallowRef } from "vue";
import OrbitaInicio from "@/views/OrbitaInicio.vue";
import OrbitaEquipo from "@/views/OrbitaEquipo.vue";
import OrbitaContactanos from "@/views/OrbitaContactanos.vue";
import OrbitaQuieness from "@/views/OrbitaQuieness.vue";
import OrbitaServicios from "@/views/OrbitaServicios.vue";
import OrbitaPlanes from "@/views/OrbitaPlanes.vue";

const activeComponent = shallowRef(OrbitaInicio);
</script>

<script>
export default {
  data() {
    return {
      activeComponent: null,
      OrbitaInicio,
    };
  },
};
</script>

<style scoped>

button {
  background-color: #FFCC01;
  height: 60px;
  width: 165px;
  color: #F6F7F9;
  font-weight: bold;
  border: 1px solid rgba(255, 255, 255, 0.3); /* Borde semi-transparente */
  background: rgba(255, 255, 255, 0.1); /* Fondo semi-transparente */
  backdrop-filter: blur(10px); /* Desenfoque del fondo */
  -webkit-backdrop-filter: blur(10px); /* Desenfoque para Safari */
  border-radius: 16px; /* Bordes redondeados */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); /* Sombra suave */
  transition: all 0.3s ease; /* Transición suave */
}

button:hover {
  border-color: #FFCC01; /* Borde amarillo en hover */
  color: #FFCC01; /* Texto amarillo en hover */
  font-size: 17px;
  border-width: 2px;
  padding: 0%;
  transform: translateY(-5px); /* Ligeramente arriba en hover */
}

button.active {
  transform: translateY(-15px);
  border-color: #FFCC01;
  color: #FFCC01;
  font-size: 17px;
  border-width: 2px;
  padding: 0%;
  background: rgba(255, 255, 255, 0.2); /* Fondo más brillante cuando está activo */
}

button {
  transition: transform 0.9s;
}

/* .card-general {
  height: calc(88vh - 90px);
  background-color: rgba(0, 0, 0, 0.28);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid #8f09c8;
  color: #fcfafa;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scrollbar-color: transparent transparent;
} */

.card-general {
  height: calc(88vh - 90px);
  background: rgba(255, 255, 255, 0.1); /* Fondo semi-transparente */
  backdrop-filter: blur(10px); /* Desenfoque del fondo */
  -webkit-backdrop-filter: blur(10px); /* Desenfoque para Safari */
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); /* Sombra suave */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Borde semi-transparente */
  color: #fcfafa;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scrollbar-color: transparent transparent;
  transition: all 0.3s ease;
}

.card-general::-webkit-scrollbar {
  display: none;
}

.logo-orbita {
  height: 60px;
  position: relative;
  top: 35px;
  left: -35px;
  z-index: 1000;
}

/* Móvil */
@media screen and (max-width: 767px) and (max-width: 767px) {
  a {
    border: none !important;
    border-radius: -46px;
    margin: 3px;
  }

  i {
    font-size: 20px;
  }

  button {
    height: 50px;
    width: 100px;
  }

  /* Botones */
  button {
    border-radius: 46px !important;
    width: 47px;
    height: 50px;
  }
}

@media screen and (min-width: 600px) {
  .card-general {
    height: calc(80vh - 20px);
  }
}

@media (min-width: 601px) and (max-width: 1279px) {

  a {
    border: none !important;
    border-radius: -46px;
    margin: -5px;
  }
}

/* Portátil */
@media screen and (min-width: 1280px) and (max-width: 1919px) {
  a {
    border: none !important;
  }
}

/* Escritorio */
@media screen and (min-width: 1920px) {
  a {
    border: none !important;
    border-radius: -46px;
    margin: 5px;
  }

  i {
    font-size: 20px;
  }
}
</style>
