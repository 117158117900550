<template>
  <section class="d-flex flex-column p-3 py-4">
    <div class="d-flex flex-wrap justify-content-center align-items-center">
      <!-- primera card  -->
      <div class="col-lg-4 card p-3">        
        <div class="d-flex justify-content-center align-items-center">
          <div>
            <img src="https://static.orbita.com.co/public/general/images/icono_admin_empresarial.png"
              alt="Administracion empresarial" />
          </div>
            <div>
              <h1 class="title-services">Administración Empresarial</h1>
              <h2 class="subtitle-services">
                Optimizamos procesos y decisiones estratégicas.
              </h2>
            </div>
        </div>
      </div>
      <!-- segunda card -->
      <div class="col-lg-4 card p-3">
        <div class="d-flex justify-content-center align-items-center">
          <div>
            <img class="" src="https://static.orbita.com.co/public/general/images/icono_marketin_y_diseño.png"
              alt="marketing y diseño" />
          </div>
          <div>
            <h1 class="title-services">Marketing y Diseño</h1>
            <h2 class="subtitle-services">
              Destaca con estrategias digitales y diseño atractivo.
            </h2>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex flex-wrap justify-content-center align-items-center">
      <!-- tercera card -->
      <div class="col-lg-3 card p-3">
        <div class="d-flex justify-content-center align-items-center">
          <div>
            <img src="https://static.orbita.com.co/public/general/images/icono_nube.png" alt="nube" />
          </div>
          <div>
            <h1 class="title-services">Nube</h1>
            <h2 class="subtitle-services">
              Garantizamos una disponibilidad del 100% para tus datos
              empresariales.
            </h2>
          </div>
        </div>
      </div>
      <!-- cuarta card -->
      <div class="col-lg-3 card p-3">
        <div class="d-flex justify-content-center align-items-center">
          <div>
            <img src="https://static.orbita.com.co/public/general/images/icono_app_medida.png" alt="App a media" />
          </div>
          <div>
            <h1 class="title-services">App a Medida</h1>
            <h2 class="subtitle-services">
              Aplicaciones personalizadas para mejorar tu productividad y
              experiencia del usuario.
            </h2>
          </div>
        </div>
      </div>
      <!-- quinta card -->
      <div class="col-lg-3 card p-3">
        <div class="d-flex justify-content-center align-items-center">
          <div>
            <img src="https://static.orbita.com.co/public/general/images/icono_seguirdad.png" alt="seguridad" />
          </div>
          <div>
            <h1 class="title-services">Seguridad</h1>
            <h2 class="subtitle-services">
              Protegemos tus activos digitales con medidas de seguridad sólidas.
            </h2>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex flex-wrap justify-content-center align-items-center">
      <!-- sexta carta -->
      <div class="col-lg-4 card p-3">
        <div class="d-flex justify-content-center align-items-center">
          <div>
            <img src="https://static.orbita.com.co/public/general/images/icono_database.png" alt="icono de database" />
          </div>
          <div>
            <h1 class="title-services">Base De Datos</h1>
            <h2 class="subtitle-services">
              Organizamos y gestionamos tu información de manera eficiente.
            </h2>
          </div>
        </div>
      </div>
      <!-- septima card -->
      <div class="col-lg-4 card p-3">
        <div class="d-flex justify-content-center align-items-center">
          <div>
            <img src="https://static.orbita.com.co/public/general/images/icono_asesoria_y_soporte.png"
              alt="Asesoria y soporte" />
          </div>
          <div>
            <h1 class="title-services">Asesoría y Soporte</h1>
            <h2 class="subtitle-services">
              Resolvemos tus desafíos tecnológicos.
            </h2>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'OrbitaServicios'
}
</script>


<style scoped>
.card {
  border-radius: 33px;
  background: rgba(25, 1, 63, 0.7); /* Fondo transparente para glassmorfismo */
  backdrop-filter: blur(10px); /* Efecto borroso de glassmorfismo */
  -webkit-backdrop-filter: blur(10px);
  color: #f6f7f9;
  width: 370px;
  height: 200px;
  margin: 10px;
  border: none;

  /* Neomorfismo: sombras suaves para crear un efecto en relieve */
  box-shadow: 8px 8px 15px rgba(0, 0, 0, 0.25),
              -8px -8px 15px rgba(255, 255, 255, 0.1);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.3),
              -10px -10px 20px rgba(255, 255, 255, 0.15);
}

.title-services {
  color: #ffcc01;
  font-size: 24px;
  font-family: "Uniform Rounded";
  margin-bottom: 10px;
}

.subtitle-services {
  color: #f6f7f9;
  font-family: "TT Hazelnuts Trl";
  font-size: 18px;
  text-align: justify;
}

.section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

@media screen and (max-width: 992px) {
  .card {
    width: 320px;
    height: 180px;
  }
}

@media screen and (max-width: 768px) {
  .section {
    flex-direction: column;
  }
  .card {
    width: 300px;
    height: auto;
  }
}


</style>

